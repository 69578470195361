import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import './GivingHeader.css'

export default () => {
    const data = useStaticQuery ( graphql`
    query GivingHeaderQuery {
      file(relativePath: {eq: "Giving/Faithful.jpg"}) {
        childImageSharp {
          fluid(maxWidth:2000,quality:100) {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
    `)
    return(
    <div>
        <div className="giving_header">
            <BackgroundImage
            fluid={data.file.childImageSharp.fluid}
            className='giving_header_Background'>
            </BackgroundImage>
        </div>
    </div>
    )   
}