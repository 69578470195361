import React from 'react'
import Navbar from '../components/NavBar/NavBar'
import GlobalFooter from '../components/Footer/GlobalFooter'
import GivingInstruction from '../components/GivingInstruction/GivingInstruction'
import GivingHeader from '../components/GivingInstruction/GivingHeader'


export default () => (
    <div>
      <div>
        <Navbar />
      </div>
      <div>
        <GivingHeader />
      </div>
      <div>
        <GivingInstruction />
      </div>
      <div>
        <GlobalFooter />
      </div>  
    </div>
)