import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Row, Col, Divider, PageHeader } from 'antd' 
import './GivingInstruction.css'

export default function Image() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Giving/GivingQR.png" }) {
        childImageSharp {
          fluid(maxWidth:2000,quality:100) {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `) 
  return (
    <div className='giving_instruction'> 
        <div className='giving_instruction_desktop'>
          <Divider className='giving_title'><h1>Online Offering via PayPal Giving Fund</h1></Divider>
          <Row gutter={16} justify="space-around">    
              <Col span={10}>  
                  
                  <div>
                      <Divider>Make an offering through PayPal Giving Fund</Divider>

                      <p>The PayPal Giving Fund enables you to make your offering with a credit card. There are no fees to either you or to CGC for using a credit card to fund your offering – 100% of your offering will go to CGC. </p>
                      <p>To make an offering to CGC general fund, please go to link below or scan the QR code on the right <a href="https://www.paypal.com/us/fundraiser/charity/4071053">CGC Paypal</a></p>
                      <p>Please leave the Name and Email address box checked so that we can show your offering through PPGF on your Annual CGC Offering Record, which will be emailed to you in January next year.</p>
                      <p>For more details about the online offering, please review the PDF instruction file below. </p>
      
                      <Divider>General Fund Only</Divider>
                      <p>PPGF does not allow you to designate your offering because there is no “memo line” feature, so all PPGF offerings to CGC will default to the General Fund. If you would like to make an offering donation to CGC Building Fund; Mission Fund; Youth Ministry, or Loving Fund, please continue giving with a check and mail to church address.​</p>
                      <p>​Got questions? Please email <a href="mailto:yuhlingc@yahoo.com">yuhlingc@yahoo.com</a></p>
                  </div>
              </Col>
              <Col span={5}>
                  <div className='giving_QR'>
                  
                  <Divider>Giving QR Code</Divider>
                  <Img 
                      className='QR_Code'
                      fluid={data.file.childImageSharp.fluid}
                      alt="Jesus is smiling at you" 
                  />
                  </div>
              </Col>
          </Row>
        </div>
        <div className='giving_instruction_mobile'>
          <Divider className='giving_title'></Divider>
          <PageHeader className='giving_title_header'
            title="Online Offering via PayPal Giving Fund"
            />
          <Row gutter={16} justify="space-around">      
              <Col span={24}>  
                  
                  <div>
                      <Divider>Make an offering via PayPal</Divider>

                      <p>The PayPal Giving Fund enables you to make your offering with a credit card. There are no fees to either you or to CGC for using a credit card to fund your offering – 100% of your offering will go to CGC. </p>
                      <p>To make an offering to CGC general fund, please go to link below or scan the QR code on the right <a href="https://www.paypal.com/us/fundraiser/charity/4071053">CGC Paypal</a></p>
                      <p>Please leave the Name and Email address box checked so that we can show your offering through PPGF on your Annual CGC Offering Record, which will be emailed to you in January next year.</p>
                      <p>For more details about the online offering, please review the PDF instruction file below. </p>
      
                      <Divider>General Fund Only</Divider>
                      <p>PPGF does not allow you to designate your offering because there is no “memo line” feature, so all PPGF offerings to CGC will default to the General Fund. If you would like to make an offering donation to CGC Building Fund; Mission Fund; Youth Ministry, or Loving Fund, please continue giving with a check and mail to church address.​</p>
                      <p>​Got questions? Please email <a href="mailto:yuhlingc@yahoo.com">yuhlingc@yahoo.com</a></p>
                  </div>
              </Col>
          </Row>
          <Row>
              <Col span={10}>
                  <div className='giving_QR'>
                  
                  <Divider>Giving QR Code</Divider>
                  <Img 
                      className='QR_Code'
                      fluid={data.file.childImageSharp.fluid}
                      alt="Jesus is smiling at you" 
                  />
                  </div>
              </Col>
          </Row>
        </div>
    </div>

  )  
}   